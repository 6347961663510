.button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 40px;
}

.count_container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  right: -8px;
  top: -8px;
  background: #f44141;
  border-radius: 120px;

  .count_text {
    font-family: var(--font-play);
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.default,
.small {
  @extend .button;
  min-width: 48px;
  width: 48px;
  height: 48px;
  color: #d3f85a;
  border: none;

  svg {
    path {
      stroke: #d3f85a;
    }
  }

  &:disabled {
    cursor: auto;
    svg {
      path {
        stroke: #939bc2;
      }
    }
  }

  &:hover:not(:disabled) {
    border: none;
  }
}

.small {
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 8px;
}
