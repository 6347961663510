@use '../../../styles/utils' as util;

.headerWrapper {
  z-index: 100;
  background-color: #0c1027;
  width: 100%;
  max-width: 1920px;
  top: 0;
  // padding: 16px 32px;
  // @media (max-width: 1400px) {
  //   padding: 16px 20px;
  // }
}

.container {
  padding: 0 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (width > 430px) {
    padding: 16px 40px;
  }
}

.mediaLinks {
  display: flex;
  gap: 8px;
  a {
    background: #191f40;
    img {
      fill: #9297b2;
    }
  }
}

.medialink_container {
  display: flex;
  gap: 8px;
}

.btn_and_menu_container {
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.btn {
  width: 198px;
  height: 60px;
  gap: 8px;
}

.navlink_container {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-left: 16px;
  padding-right: 32px;

  & > * {
    z-index: 2;
  }
  @media (max-width: 1560px) {
    gap: 16px;
  }
}

.mobileMenuWrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background-color: #191f40;
  border-radius: 40px;

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.blockLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  // @media (max-width: 1400px) {
  //   width: 100%;
  // }

  .leftBlockMobile {
    display: flex;
    gap: 16px;
  }
}

.blockRight {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;

  // @media (max-width: 1400px) {
  //   width: 100%;
  // }

  .authWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // justify-content: flex-end; //вернуть когда дизайнер придумает что-то с меню
    width: 100%;
    gap: 10px;
  }

  .social {
    height: min-content;
    width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 6px;
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(4px);
    border-radius: 120px;
    @media (max-width: 1425px) {
      display: none;
    }
  }
}
.socLinkAndButtonCon {
  gap: 24px;
  display: flex;
  .buttonPatrnershipCon {
    .buttonPatrnership {
      border-radius: 56px;
      background: #191f40;
      background-image: none;
      gap: 8px;
      p {
        color: #fff;
        font-family: var(--font-tthoves);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }
}

.promoCon {
  background: #191f40;
  border-radius: 120px;
  flex-shrink: 0;
}

.countContainer {
  display: flex;
  flex-direction: column;
  min-width: 58px;
  .countUser {
    gap: 6px;
    display: flex;
    color: #fff;
    text-align: right;
    font-family: var(--font-tektur);
    font-size: 14px;
    font-style: normal;
    min-width: 45px;
    font-weight: 400;
    width: max-content;
    line-height: normal;
    .imageContainer {
      position: relative;
      width: 10px;
      height: 10px;
      .image2 {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: opacity 1s ease-in-out;
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .countTxt {
    color: #d6d6df;
    margin-left: 8px;
    font-family: var(--font-tektur);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
  }
}
