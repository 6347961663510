.container {
  padding: 12px;
  width: 100%;
  border-radius: 12px;
  background: #191f40;
  justify-content: flex-start;
  gap: 8px;

  .main {
    display: flex;
    flex-direction: row;
    width: fit-content;
    gap: 8px;
    cursor: pointer;
    align-items: center;
    transition: color 0.15s ease;
  }

  .main_icon {
    width: 24px;
    height: 24px;
  }

  .main_text {
    font-family: var(--font-play);
    text-transform: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #fff;
    transition: color 0.15s ease;
  }
}
