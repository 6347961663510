@import './nprogress.css';

:root {
    --primary-color: #d3f85a;
    --secondary-color: rgba(20, 24, 48);
    --background-color: #0c1027;
    /* для обратной совместимости */
    --font-tthoves: 'Inter', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-ttsupermolotneue: 'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-arame: 'Tektur', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    /* новые шрифты */
    --font-inter: 'Inter', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-play: 'Play', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';
    --font-tektur: 'Tektur', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols';

    --rarities-bg-gray: linear-gradient(90deg, rgba(111, 111, 111, 0) 0%, rgba(111, 111, 111, 0.2) 100%);
    --rarities-bg-light-blue: linear-gradient(90deg, rgba(92, 152, 239, 0) 0%, rgba(92, 152, 239, 0.2) 100%);
    --rarities-bg-blue: linear-gradient(90deg, rgba(60, 96, 252, 0) 0%, rgba(60, 96, 252, 0.2) 100%);
    --rarities-bg-purple: linear-gradient(90deg, rgba(110, 48, 250, 0) 0%, rgba(110, 48, 250, 0.2) 100%);
    --rarities-bg-pink: linear-gradient(90deg, rgba(218, 71, 253, 0) 0%, rgba(218, 71, 253, 0.2) 100%);
    --rarities-bg-red: linear-gradient(90deg, rgba(236, 67, 60, 0) 0%, rgba(236, 67, 60, 0.2) 100%);
    --rarities-bg-yellow: linear-gradient(90deg, rgba(223, 187, 39, 0) 0%, rgba(223, 187, 39, 0.2) 100%);

    --rarities-bg-gray-reverse: linear-gradient(-90deg, rgba(111, 111, 111, 0) 0%, rgba(111, 111, 111, 0.2) 100%);
    --rarities-bg-light-blue-reverse: linear-gradient(-90deg, rgba(92, 152, 239, 0) 0%, rgba(92, 152, 239, 0.2) 100%);
    --rarities-bg-blue-reverse: linear-gradient(-90deg, rgba(60, 96, 252, 0) 0%, rgba(60, 96, 252, 0.2) 100%);
    --rarities-bg-purple-reverse: linear-gradient(-90deg, rgba(110, 48, 250, 0) 0%, rgba(110, 48, 250, 0.2) 100%);
    --rarities-bg-pink-reverse: linear-gradient(-90deg, rgba(218, 71, 253, 0) 0%, rgba(218, 71, 253, 0.2) 100%);
    --rarities-bg-red-reverse: linear-gradient(-90deg, rgba(236, 67, 60, 0) 0%, rgba(236, 67, 60, 0.2) 100%);
    --rarities-bg-yellow-reverse: linear-gradient(-90deg, rgba(223, 187, 39, 0) 0%, rgba(223, 187, 39, 0.2) 100%);

    --rarities-bg-gray-vertical: linear-gradient(180deg, rgba(111, 111, 111, 0) 0%, rgba(111, 111, 111, 0.2) 100%);
    --rarities-bg-light-blue-vertical: linear-gradient(180deg, rgba(92, 152, 239, 0) 0%, rgba(92, 152, 239, 0.2) 100%);
    --rarities-bg-blue-vertical: linear-gradient(180deg, rgba(60, 96, 252, 0) 0%, rgba(60, 96, 252, 0.2) 100%);
    --rarities-bg-purple-vertical: linear-gradient(180deg, rgba(110, 48, 250, 0) 0%, rgba(110, 48, 250, 0.2) 100%);
    --rarities-bg-pink-vertical: linear-gradient(180deg, rgba(218, 71, 253, 0) 0%, rgba(218, 71, 253, 0.2) 100%);
    --rarities-bg-red-vertical: linear-gradient(180deg, rgba(236, 67, 60, 0) 0%, rgba(236, 67, 60, 0.2) 100%);
    --rarities-bg-yellow-vertical: linear-gradient(180deg, rgba(223, 187, 39, 0) 0%, rgba(223, 187, 39, 0.2) 100%);

    --rarities-bg-gray-reverse-vertical: linear-gradient(0deg, rgba(111, 111, 111, 0) 0%, rgba(111, 111, 111, 0.2) 100%);
    --rarities-bg-light-blue-reverse-vertical: linear-gradient(0deg, rgba(92, 152, 239, 0) 0%, rgba(92, 152, 239, 0.2) 100%);
    --rarities-bg-blue-reverse-vertical: linear-gradient(0deg, rgba(60, 96, 252, 0) 0%, rgba(60, 96, 252, 0.2) 100%);
    --rarities-bg-purple-reverse-vertical: linear-gradient(0deg, rgba(110, 48, 250, 0) 0%, rgba(110, 48, 250, 0.2) 100%);
    --rarities-bg-pink-reverse-vertical: linear-gradient(0deg, rgba(218, 71, 253, 0) 0%, rgba(218, 71, 253, 0.2) 100%);
    --rarities-bg-red-reverse-vertical: linear-gradient(0deg, rgba(236, 67, 60, 0) 0%, rgba(236, 67, 60, 0.2) 100%);
    --rarities-bg-yellow-reverse-vertical: linear-gradient(0deg, rgba(223, 187, 39, 0) 0%, rgba(223, 187, 39, 0.2) 100%);
}

html,
body {
    padding: 0;
    margin: 0;
    background-color: var(--background-color);
    scrollbar-width: thin;
    scrollbar-color: #808080 transparent;
    height: 100%;
}

html {
    scroll-behavior: smooth;
}

html.normal-scroll {
    scroll-behavior: auto;
}

#__next {
    height: inherit;
}

h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.3);
    border-radius: 20px;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: var(--font-inter);
}

a {
    color: inherit;
    text-decoration: none;
}
