.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 4px 4px 12px;
  gap: 8px;
  border-radius: 120px;
  height: 48px;
  cursor: pointer;
}
.text {
  font-family: var(--font-play);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-right: 8px;
  white-space: nowrap;
}

.default,
.dark {
  @extend .button;
  background: #d3f85a;
  color: #0c1027;

  box-shadow: 0 0 0 4px rgba(211, 248, 90, 0.16);
  border: none;

  .button_text {
    @extend .text;
    color: #0c1027;
  }

  .oval_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: #0c1027;
    border-radius: 40px;
    svg {
      path {
        stroke: #d3f85a;
      }
    }
  }

  svg {
    path {
      stroke: #0c1027;
    }
  }

  &:disabled {
    opacity: 0.56;
    cursor: auto;
  }

  &:hover:not(:disabled) {
    background: #dcf97b;

    box-shadow: 0 0 0 4px rgba(211, 248, 90, 0.32);
    border: none;
  }
}

.dark {
  background: #191f40;
  color: #d3f85a;
  box-shadow: none;

  .button_text {
    color: #d3f85a;
  }

  .oval_container {
    background: #d3f85a;
    svg {
      path {
        stroke: #0c1027;
      }
    }
  }

  svg {
    path {
      stroke: #d3f85a;
    }
  }

  &:disabled {
    opacity: 1;
    background: #191f40;
    svg {
      path {
        stroke: #939bc2;
      }
    }
    .button_text {
      color: #939bc2;
    }
    .oval_container {
      background: #252e5c;
      svg {
        path {
          stroke: #939bc2;
        }
      }
    }
  }

  &:hover:not(:disabled) {
    box-shadow: none;
    background: #252e5c;
  }
}
