@use '../styles/utils' as util;

.mainBox {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  overflow-x: clip;
  z-index: 0;
  max-width: 1920px;
  gap: 16px;

  @media screen and (width > 430px) {
    gap: 0;
  }
}

.pageWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-inline: 8px;
  @media (max-width: 500px) {
    padding-inline: 16px;
  }
}

.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  flex: 1 1 auto;
}
