.common-text {
  font-family: var(--font-inter);
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #939bc2;
  text-decoration: none;
  width: fit-content;
}

.container {
  width: 100%;
  margin-bottom: 8px;
  gap: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  @media (max-width: 800px) {
    margin-top: 100px;
  }
  position: relative;
  overflow: hidden;

  .pay_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #191f40;
    border-radius: 12px;
    gap: 30px;

    @media (max-width: 660px) {
      gap: 0;
      flex-direction: column;
    }

    width: 100%;
  }

  .payBlock {
    display: flex;
    align-items: center;
    padding: 32px 38px 32px 0;
    flex-wrap: wrap;

    gap: 34px;

    @media (max-width: 1000px) {
      gap: 27px;
      padding: 32px 60px 32px 16px;
    }
  }

  .textPay {
    display: flex;
    align-items: center;
    color: #939bc2;

    font-family: var(--font-inter);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    white-space: nowrap;
    padding-left: 32px;

    @media (max-width: 660px) {
      font-size: 14px;
      padding: 32px 0 0 16px;
    }
  }

  .footerDown {
    display: flex;
    flex-direction: column;
    background: #101530;
    border-radius: 12px;
    padding: 48px 32px;
    z-index: 0;
    overflow: hidden;

    @media (max-width: 800px) {
      padding: 40px 16px;
    }
  }

  .topBox {
    width: 100%;
    @media (max-width: 1000px) {
      gap: 24px;
    }

    .leftBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .urlHyper {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 24px;

        @media (max-width: 800px) {
          gap: 8px;
        }
      }

      .urlText {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
    }

    .rightBox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      gap: 210px;
      padding: 0 180px 80px 0;

      @media (max-width: 1000px) {
        gap: 80px;
      }

      @media (max-width: 800px) {
        padding: 0 36px 90px 0;
        margin-top: 32px;
      }
      @media (max-width: 430px) {
        gap: 24px;
      }
      @media (max-width: 320px) {
        flex-direction: column;
      }

      .footerLinksContainer {
        z-index: 999;
        gap: 40px;
        @media (max-width: 1000px) {
          gap: 24px;
        }

        display: flex;
        flex-direction: column;
        width: 120px;

        .linkText {
          @extend .common-text;
          font-size: 12px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      .linkText:after {
        display: flex;
        position: relative;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #fff;
        content: '';
        transition: width 0.3s ease-out;
      }

      .linkText:hover:after,
      .linkText:focus:after {
        width: 100%;
      }
    }

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 32px;
    border-bottom: 1px solid #212646;

    @media (max-width: 1000px) {
      padding-bottom: 0;
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  .button {
    width: 202px;
    height: 48px;
    border-radius: 120px;

    .button__text {
      @extend .common-text;
      font-size: 12px;
      line-height: 24px;
      color: #0c1027;
    }
  }

  .coopyrightText {
    @extend .common-text;
    font-size: 11px;
    line-height: 16px;
  }

  .bottomBox {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1000px) {
      margin-top: 24px;
    }

    .bottom_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // flex-wrap: wrap;

      @media (max-width: 1000px) {
        align-items: flex-start;
      }

      @media (max-width: 800px) {
        flex-direction: column;
      }
    }

    .bottomRight {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 110px;

      @media (max-width: 1000px) {
        flex-direction: column;
        gap: 40px;
      }

      @media (max-width: 800px) {
        width: 100%;
        margin-top: 40px;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .titleSocial {
      @extend .common-text;
      font-size: 12px;
      line-height: 24px;
      white-space: nowrap;

      @media (max-width: 800px) {
        display: none;
      }
    }

    .footerLinksBottom {
      cursor: pointer;
    }

    .footerLinkBottom {
      @extend .common-text;
      font-family: var(--font-play);
      font-size: 12px;
      line-height: 24px;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.2s linear;
      padding-right: 25px;

      &:hover {
        opacity: 0.7;
      }
    }

    .socialLinksContainer {
      display: flex;
      gap: 12px;
    }
  }
}

.imgBack {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

@media (max-width: 600px) {
  .container .topBox .rightBox .socialLinksContainer {
    justify-content: center;
    margin-top: 60px;
  }

  .imgBack {
    display: none;
  }
}

.imgLogo {
  cursor: pointer;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.5;
  }
}

.footerImageWrapper {
  height: 424px;
  user-select: none;
  position: absolute;
  left: 45%;
  bottom: 0;
  transform: translate(-50%);
  z-index: -3;
  background-color: #0c1027;

  @media (max-width: 1200px) {
    left: 50%;
  }

  @media (max-width: 800px) {
    display: none;
  }

  .gradient {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    top: 0;
    background: radial-gradient(
                    50.04% 50.04% at 50% 50%,
                    rgba(0, 0, 0, 0) 0%,
                    rgb(16, 21, 48) 100%
    );

    // @media (max-width: 1200px) {
    //   height: 30%;
    //   width: 30%;
    // }
  }
}

.pointLink {
  display: flex;
  gap: 6px;
}

.mediaCircle {
  border-radius: 50%;
  background: #9297b2;
  cursor: pointer;
}
