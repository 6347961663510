.container {
  border-radius: 120px;
  gap: 16px;
  display: flex;
  padding: 4px 4px 4px 14px;
  align-items: center;
  justify-content: center;

  .text {
    font-family: var(--font-inter);
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #939bc2;
    white-space: nowrap;
  }

  .type_login {
    display: flex;
    gap: 4px;

    button {
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: none;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      @media (max-width: 1100px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.telegramContainer {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: #0c1027;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  :global(iframe) {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 40px !important;
    height: 40px !important;
    opacity: 0.01 !important;
    cursor: pointer !important;
    z-index: 1;
  }
  
  &::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/icons/login/telegram.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
}
